// socialLinks.js
export const twitter = "https://x.com/Lenam0n";
export const linkedin = "https://www.linkedin.com/in/lenam0n/";
export const github = "https://github.com/Lenam0n";
export const email = "Lena.Wiederstein1234@gmail.com";
export const discord = {
  name: "Lena_x3",
  invite: "https://discord.gg/Qc8CmsxJUh",
};
export const vlr = "https://www.vlr.gg/player/28670/lenam0n";
export const hltv = "https://www.hltv.org";
export const liquipedia = "https://liquipedia.net/valorant/Lenam0n";
export const spikegg = "https://www.thespike.gg/player/lenam0n/48357";
export const ribgg = "https://www.rib.gg/players/lenam0n/25879";
